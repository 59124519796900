<template>
    <section class="blockElement space bg-white">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-md-4">
                        <div class="pt-0 text-center">
                            <v-lazy-image height="150" width="150" class="d-block mx-auto my-4" src="/assets/images/failedGif.gif" :alt="$t('pages_failure.text2')" />
                            <h2 class="red mb-4">{{$t('pages_failure.text1')}}</h2>
                            <!-- <p class="mb-4">Payment for Order <b>dummy@gmail.com</b> could not proceed. <br>Try again </p>
                            <p class="neutralGrey f-12">If you want to become a Leader and have a public profile click the button below.</p> -->
                        </div>
                        <div class="modal-footer border-0 justify-content-center">
                            <router-link class="button fillBtn zulu_btn d-flex px-md-5" :to="{name: 'subscription'}">{{$t('pages_failure.text3')}}</router-link>
                        </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
        data(){
            return {
            };
        },
        mounted(){
            let details = navigator.userAgent;
            let regexp = /android|iphone|kindle|ipad/i;
            if(regexp.test(details)){
                window.open('zulutrade://payment/failure')
            }
        }
    };
</script>